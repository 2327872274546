div>nav>div>div>div>div>#basic-nav-dropdown {
    color: white;
}

div>nav>div>div>div>div>.dropdown-menu.show {
    display: inline-block;
}

@media screen and (max-width: 991px) {
    .dropdown-menu.show {
        background-color: rgba(255, 255, 255, .25) !important;
    }
}

@media screen and (min-width: 992px) {
    .dropdown-menu.show {
        background-color: rgba(33, 37, 41, .25) !important;
    }
}