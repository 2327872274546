.night {
    /* position: relative; // It pushes sibling element further down. I need to make it absolute to overlay. */
    position: absolute;
    width: 100%;
    /* height: 100%; // Stars are placed closer to ground */
    height: 30vh;
    transform: rotateZ(15deg);
}

.shooting_star {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 2px;
    background: linear-gradient(-45deg, rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    border-radius: 999px;
    filter: drop-shadow(0 0 6px rgba(105, 155, 255, 1));
    animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
}

.shooting_star::before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    animation: shining 3000ms ease-in-out infinite;
}

.shooting_star::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    animation: shining 3000ms ease-in-out infinite;
    transform: translateX(50%) rotateZ(-45deg);
}

.shooting_star:nth-child(1) {
    top: calc(50% - -164px);
    left: calc(50% - 141px);
    animation-delay: 9254ms;
}

.shooting_star:nth-child(1)::before,
.shooting_star:nth-child(1)::after {
    animation-delay: 9254ms;
}

.shooting_star:nth-child(2) {
    top: calc(50% - -60px);
    left: calc(50% - 95px);
    animation-delay: 3368ms;
}

.shooting_star:nth-child(2)::before,
.shooting_star:nth-child(2)::after {
    animation-delay: 3368ms;
}

.shooting_star:nth-child(3) {
    top: calc(50% - -168px);
    left: calc(50% - 65px);
    animation-delay: 8488ms;
}

.shooting_star:nth-child(3)::before,
.shooting_star:nth-child(3)::after {
    animation-delay: 8488ms;
}

.shooting_star:nth-child(4) {
    top: calc(50% - -84px);
    left: calc(50% - 57px);
    animation-delay: 5558ms;
}

.shooting_star:nth-child(4)::before,
.shooting_star:nth-child(4)::after {
    animation-delay: 5558ms;
}

.shooting_star:nth-child(5) {
    top: calc(50% - 49px);
    left: calc(50% - 179px);
    animation-delay: 4993ms;
}

.shooting_star:nth-child(5)::before,
.shooting_star:nth-child(5)::after {
    animation-delay: 4993ms;
}

.shooting_star:nth-child(6) {
    top: calc(50% - -99px);
    left: calc(50% - 169px);
    animation-delay: 6717ms;
}

.shooting_star:nth-child(6)::before,
.shooting_star:nth-child(6)::after {
    animation-delay: 6717ms;
}

.shooting_star:nth-child(7) {
    top: calc(50% - -124px);
    left: calc(50% - 23px);
    animation-delay: 6567ms;
}

.shooting_star:nth-child(7)::before,
.shooting_star:nth-child(7)::after {
    animation-delay: 6567ms;
}

.shooting_star:nth-child(8) {
    top: calc(50% - 152px);
    left: calc(50% - 294px);
    animation-delay: 4712ms;
}

.shooting_star:nth-child(8)::before,
.shooting_star:nth-child(8)::after {
    animation-delay: 4712ms;
}

.shooting_star:nth-child(9) {
    top: calc(50% - -164px);
    left: calc(50% - 246px);
    animation-delay: 4269ms;
}

.shooting_star:nth-child(9)::before,
.shooting_star:nth-child(9)::after {
    animation-delay: 4269ms;
}

.shooting_star:nth-child(10) {
    top: calc(50% - 194px);
    left: calc(50% - 266px);
    animation-delay: 4798ms;
}

.shooting_star:nth-child(10)::before,
.shooting_star:nth-child(10)::after {
    animation-delay: 4798ms;
}

.shooting_star:nth-child(11) {
    top: calc(50% - -168px);
    left: calc(50% - 100px);
    animation-delay: 7124ms;
}

.shooting_star:nth-child(11)::before,
.shooting_star:nth-child(11)::after {
    animation-delay: 7124ms;
}

.shooting_star:nth-child(12) {
    top: calc(50% - -4px);
    left: calc(50% - 265px);
    animation-delay: 948ms;
}

.shooting_star:nth-child(12)::before,
.shooting_star:nth-child(12)::after {
    animation-delay: 948ms;
}

.shooting_star:nth-child(13) {
    top: calc(50% - -169px);
    left: calc(50% - 204px);
    animation-delay: 8178ms;
}

.shooting_star:nth-child(13)::before,
.shooting_star:nth-child(13)::after {
    animation-delay: 8178ms;
}

.shooting_star:nth-child(14) {
    top: calc(50% - 162px);
    left: calc(50% - 232px);
    animation-delay: 2736ms;
}

.shooting_star:nth-child(14)::before,
.shooting_star:nth-child(14)::after {
    animation-delay: 2736ms;
}

.shooting_star:nth-child(15) {
    top: calc(50% - -80px);
    left: calc(50% - 163px);
    animation-delay: 9980ms;
}

.shooting_star:nth-child(15)::before,
.shooting_star:nth-child(15)::after {
    animation-delay: 9980ms;
}

.shooting_star:nth-child(16) {
    top: calc(50% - 2px);
    left: calc(50% - 112px);
    animation-delay: 4924ms;
}

.shooting_star:nth-child(16)::before,
.shooting_star:nth-child(16)::after {
    animation-delay: 4924ms;
}

.shooting_star:nth-child(17) {
    top: calc(50% - 196px);
    left: calc(50% - 32px);
    animation-delay: 2475ms;
}

.shooting_star:nth-child(17)::before,
.shooting_star:nth-child(17)::after {
    animation-delay: 2475ms;
}

.shooting_star:nth-child(18) {
    top: calc(50% - 146px);
    left: calc(50% - 35px);
    animation-delay: 403ms;
}

.shooting_star:nth-child(18)::before,
.shooting_star:nth-child(18)::after {
    animation-delay: 403ms;
}

.shooting_star:nth-child(19) {
    top: calc(50% - -145px);
    left: calc(50% - 133px);
    animation-delay: 517ms;
}

.shooting_star:nth-child(19)::before,
.shooting_star:nth-child(19)::after {
    animation-delay: 517ms;
}

.shooting_star:nth-child(20) {
    top: calc(50% - -143px);
    left: calc(50% - 249px);
    animation-delay: 8405ms;
}

.shooting_star:nth-child(20)::before,
.shooting_star:nth-child(20)::after {
    animation-delay: 8405ms;
}

@keyframes tail {
    0% {
        width: 0;
    }

    30% {
        width: 100px;
    }

    100% {
        width: 0;
    }
}

@keyframes shining {
    0% {
        width: 0;
    }

    50% {
        width: 30px;
    }

    100% {
        width: 0;
    }
}

@keyframes shooting {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(300px);
    }
}

@keyframes sky {
    0% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(405deg);
    }
}