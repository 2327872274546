.projects-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    /* background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%); */
    background: url('../../public/assets/images/projects-bg/starry-sky-night.jpg');
    background-size: cover;
    overflow-y: auto;
    overflow-x: hidden;
}

/* .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
} */

.custom-font {
    font-family: 'Nothing You Could Do', cursive;
}

.focus-card {
    transition: transform 200ms ease-in-out;
}

.focus-card:hover {
    transform: scale(1.05);
    /* Increase the size of the card */

    box-shadow: 0px 0px 9px 4px #747DE8;
    animation: glow 1.5s linear infinite alternate;
}

@keyframes glow {
    to {
        box-shadow: 0px 0px 30px 20px #535FED;
    }
}

/* @keyframes orbit {
    from {
        -webkit-transform: rotate(0deg) translateX(150px) rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg) translateX(150px) rotate(-360deg);
    }
} */