.about-title-font {
    font-family: 'Bruno Ace', cursive;
}

.about-bg {
    /* height: 100vh; // This hides few bottom pixels */
    height: 100%;
    width: 100%;
    position: absolute;
    /* background: repeating-linear-gradient(90deg, lightblue, white, lightblue); */
    background-color: ghostwhite;
    overflow: auto;
}

/* 
    mobile (320 - 480)
    tablets (481 - 768)
    small laptops (769 - 1024)
    desktops (1025 - 1200)
    larger screens (1201 - max)
*/
@media only screen and (max-width: 768px) {
    .about-bg {
        background: url('../../public/assets/images/about/mountain-sky.jpg');
        background-size: cover;
    }

    .branch-wheat-bg {
        display: none;
    }
}

@media only screen and (min-width: 769px) {
    .mountain-sky-bg {
        display: none;
    }

    .about-bg {
        background: url('../../public/assets/images/about/branch-wheat-with-bright.jpg');
        background-size: cover;
    }
}

.about-footer {
    left: 0;
    bottom: 0;
    /* position: fixed; stays at the bottom of the screen permanently */

    /* stay at the bottom of the screen when scrolling */
    position: sticky;
    top: 100%;
    z-index: 100;

    /* 
    viewport width is set relative to viewport width, 
    making footer too large or too small
    */
    padding-top: 2vw;
    padding-bottom: 2vw;
    width: 100%;
    background-color: rgba(33, 37, 41, .25) !important;
    color: white;
    text-align: center;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

div.container div p img {
    width: -webkit-fill-available;
}

/* Loading indicator */
.lds-ellipsis {
    /* change color here */
    color: #1c4c5b
}

.lds-ellipsis,
.lds-ellipsis div {
    box-sizing: border-box;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33.33333px;
    width: 13.33333px;
    height: 13.33333px;
    border-radius: 50%;
    background: currentColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}