@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200');

.projects-openai-bg {
    background-image: url('https://static.pexels.com/photos/414171/pexels-photo-414171.jpeg');
    background-size: cover;
    -webkit-animation: slidein 100s;
    animation: slidein 100s;

    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    -webkit-animation-direction: alternate;
    animation-direction: alternate;

    position: relative;
    height: 100vh;
    overflow: auto;
}

@-webkit-keyframes slidein {
    from {
        background-position: top;
        background-size: 3000px;
    }

    to {
        background-position: -100px 0px;
        background-size: 2750px;
    }
}

@keyframes slidein {
    from {
        background-position: top;
        background-size: 3000px;
    }

    to {
        background-position: -100px 0px;
        background-size: 2750px;
    }

}

.projects-openai-font {
    text-align: center;
    color: white;
    font-family: 'Source Code Pro', monospace;
    text-transform: uppercase;
}