.blogs-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    background: url('../../public/assets/images/blogs-bg/a-white-abstract-background-with-wavy-lines.jpg');
    background-repeat: repeat;
    background-size: cover;
    overflow: auto;
}

.blog-new-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    background: url('../../public/assets/images/blogs-bg/blogs-bg-3.jpeg');
    background-size: cover;
    overflow: auto;
}

.blog-new-form-box {
    padding: 20px;
    background: rgba(224, 224, 224, 0.85);
    border-radius: 20px;
}

.blog-details-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 0.2) 0%, rgba(2, 0, 36, 0) 50%, rgba(2, 0, 36, 0.2) 100%);
    overflow: auto;
}

.blog-details-bg img {
    width: 100%;
}

.blog-details-loading-bg {
    background-color: hsl(223, 90%, 95%);
    height: 100vh;
    place-items: center;
    display: grid;
}

.blog-card-circle {
    border-radius: 0%;
    width: 35px;
    height: 35px;
    padding-top: 25%;
    margin-top: 0px;
    margin-right: 0px;
    background: #fff;
    border: 5px ridge whitesmoke;
    color: #000;
    text-align: center;
    font: 10px Arial, sans-serif;
}

.center {
    /* Center vertically and horizontally */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
}

.footer {
    left: 0;
    bottom: 0;
    padding-top: 2vw;
    padding-bottom: 2vw;
    width: 100%;
    background-color: rgba(2, 0, 36, 0.25);
    color: white;
    text-align: center;
}

.font-cherry-bomb {
    font-family: 'Cherry Bomb One', cursive;
}

.font-courgette {
    font-family: 'Courgette', cursive;
}

.modal-body img {
    max-width: 100%;
    /* display: block;
    height: auto;
    margin: auto;
    float: none !important; */
}

@media only screen and (min-width: 992px) {
    .blog-details-text {
        margin-left: 10rem;
        margin-right: 10rem;
    }
}