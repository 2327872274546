.contact-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden !important;
    background: rgba(255, 223, 0, 0.2);
}

.contact-form-box {
    padding: 20px;
    background: rgba(255, 223, 0, 0);
    /* border-radius: 20px; */
}

/* Below are CSS for leaves-falling animation */
@keyframes fall {
    0% {
        transform: translateY(-100px) rotate(0deg);
        opacity: 1;
    }

    100% {
        transform: translateY(100vh) rotate(360deg);
        opacity: 0;
    }
}

.leaf {
    position: absolute;
    top: 0;
    width: 30px;
    height: 30px;
    background-image: url('../../public/assets/images/contact/free_vector_autumn_leaves.jpg');
    background-size: cover;
    animation: fall linear infinite;
    animation-duration: var(--fall-duration);
    left: var(--fall-left);
    z-index: -1;
}

.leaf:nth-child(odd) {
    animation-duration: calc(var(--fall-duration) + 2s);
}

.leaf:nth-child(even) {
    animation-duration: calc(var(--fall-duration) - 2s);
}